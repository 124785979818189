const env = {
   // identityApi: "http://cdcappidentityapi-test.us-west-2.elasticbeanstalk.com",
   // identityApi: "http://certprodev.ap-/southeast-1.elasticbeanstalk.com",
   // backend: "http://43.204.229.211"
   // backend: "http://test.neocertpro.ncs-apps.com"
   // backend:"https://prd.neocertpro.ncs-apps.com/"

   identityApi: "http://identity-cms-blue-stage.ap-southeast-1.elasticbeanstalk.com",
   backend: "http://test.neocertpro.ncs-apps.com"
   // identityApi: "http://cdcappidentityapi-test.ap-south-1.elasticbeanstalk.com",
	// backend: "http://13.233.55.99"
   // backend: "http://65.0.76.231/"
   
   // backend: "http://localhost:8081"
}

export default env;